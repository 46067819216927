<div class="list-wrapper"><div class="download-list">
  <div class="header">
    <div>Release</div>
    <div>Notes</div>
    <div>Direct from iana.org</div>
    <div>.zip format</div>
    <div>&#64;tubular/time</div>
  </div>

  @for (version of versions; track version) {
    <div class="version">{{version}}</div>
    <i class="fas fa-info-circle fa-2x info-icon" [class.disabled]="!notes[version]" (click)="releaseNote = version"></i>
    <div class="links">
      <a [class.invalid-link]="!linkValid(getTarFullLink(version))" download [href]="getTarFullLink(version)">{{nameFromLink(getTarFullLink(version))}}</a>
      <a [class.invalid-link]="!linkValid(getTarCodeLink(version))" download [href]="getTarCodeLink(version)">{{nameFromLink(getTarCodeLink(version))}}</a>
      <a [class.invalid-link]="!linkValid(getTarDataLink(version))" download [href]="getTarDataLink(version)">{{nameFromLink(getTarDataLink(version))}}</a>
    </div>
    <div class="links">
      <a [class.invalid-link]="!linkValid(getTarFullLink(version))" download [href]="getZipFullLink(version)">{{nameFromLink(getZipFullLink(version))}}</a>
      <a [class.invalid-link]="!linkValid(getTarCodeLink(version))" download [href]="getZipCodeLink(version)">{{nameFromLink(getZipCodeLink(version))}}</a>
      <a [class.invalid-link]="!linkValid(getTarDataLink(version))" download [href]="getZipDataLink(version)">{{nameFromLink(getZipDataLink(version))}}</a>
    </div>
    @if (version >= '2021') {
      <div class="links">
        <a [href]="getTypeScriptLink(version, 'small')" target="_blank">{{nameFromLink(getTypeScriptLink(version, 'small'))}}</a>
        <a [href]="getTypeScriptLink(version, 'large')" target="_blank">{{nameFromLink(getTypeScriptLink(version, 'large'))}}</a>
        <a [href]="getTypeScriptLink(version, 'large-alt')" target="_blank">{{nameFromLink(getTypeScriptLink(version, 'large-alt'))}}</a>
      </div>
    }
    @else {
      <div class="tze-disabled"><br>n/a</div>
    }
  }
</div></div>
<tze-release-note [(release)]="releaseNote"></tze-release-note>
