<div class="top-panel">
  <div class="edit-mode">
    <div class="label-pair">
      <p-radioButton #trackCurrent name="editMode" [value]="true" [(ngModel)]="running"></p-radioButton>
      <label [for]="trackCurrent">Track current time</label>
    </div>
    <div class="label-pair">
      <p-radioButton #allowEdit name="editMode" [value]="false" [(ngModel)]="running"></p-radioButton>
      <label [for]="allowEdit">Allow time to be edited</label>
    </div>
    <button pButton [disabled]="running" class="p-button" (click)="now()">Now</button>
  </div>
</div>
<div #clocksScroller class="clocks">
  <div class="clock-label">Your local time, {{localZone}}</div>
  <div class="clock-row">
    <tbw-time-editor #localClock tai [options]="LOCAL_OPTS" [min]="MIN_YEAR" [max]="MAX_YEAR"
      [viewOnly]="running" [(ngModel)]="time" [timezone]="localZone"></tbw-time-editor>
    <div class="extra-on-right">
      @if (running) {
        <div class="system-diff">{{systemDiff}}ms</div>
      }
    </div>
  </div>
  <div class="dst-legend">DST symbols: <b>§</b>: 1 hour, <b>^</b>: ½ hour, <b>#</b>: 2 hour,
  <b>❄︎</b>: negative DST, <b>~</b>: other positive DST</div>
  <div class="clock-label">Your local time, ISO formatted</div>
  <tbw-time-editor tai [options]="ISO_SHORT_OPTS" [min]="MIN_YEAR" [max]="MAX_YEAR"
    [viewOnly]="running" [(ngModel)]="time" [timezone]="localZone"></tbw-time-editor>
  <div class="clock-label">{{utForm}}, TAI {{deltaTai}}s</div>
  <tbw-time-editor tai [options]="ISO_SHORT_OPTS" [min]="MIN_YEAR" [max]="MAX_YEAR"
    [viewOnly]="running" [(ngModel)]="time" timezone="UTC"></tbw-time-editor>
  <div class="clock-label">{{gpsForm}}, TAI - 19s</div>
  <tbw-time-editor tai [options]="ISO_SHORT_OPTS" [min]="MIN_YEAR" [max]="MAX_YEAR"
    [viewOnly]="running" [(ngModel)]="gps" timezone="TAI"></tbw-time-editor>
  <div class="clock-label">{{taiForm}} (International Atomic Time)</div>
  <tbw-time-editor tai [options]="ISO_SHORT_OPTS" [min]="MIN_YEAR" [max]="MAX_YEAR"
    [viewOnly]="running" [(ngModel)]="time" timezone="TAI"></tbw-time-editor>
  <div class="clock-label">TT (Terrestrial Time, aka TDT), TAI + 32.184s</div>
  <div class="clock-row">
    <tbw-time-editor tai [options]="ISO_SHORT_OPTS" [min]="MIN_YEAR" [max]="MAX_YEAR"
      [viewOnly]="running" [(ngModel)]="tt" timezone="TAI"></tbw-time-editor>
    @if (extraClocks.length === 0) {
      <div class="extra-on-right"><div>
        <ng-container *ngTemplateOutlet="addButton"></ng-container>
      </div></div>
    }
  </div>

  @for (clock of extraClocks; track clock; let index = $index; let last = $last) {
    <div class="clock-label">{{clock.zone.replace('/Kiev', '/Kyiv')}}</div>
    <div #clockRow class="clock-row">{{checkIfNewClock(index, clockRow)}}
      <div class="extra-on-left"><div>
        <p-button pRipple icon="pi pi-times" (click)="closeClock(index)" variant="outlined" [rounded]="true"
                  severity="danger"></p-button>
      </div></div>
      <tbw-time-editor tai [options]="clock.localFormat ? LOCAL_OPTS : ISO_OPTS" [min]="MIN_YEAR" [max]="MAX_YEAR"
        [viewOnly]="running" [(ngModel)]="time" [timezone]="clock.zone"></tbw-time-editor>
      @if (last) {
        <div class="extra-on-right"><div>
          <ng-container *ngTemplateOutlet="addButton"></ng-container>
        </div></div>
      }
    </div>
  }
</div>

<ng-template #addButton>
  @if (window.innerWidth > 740) {
    <p-button icon="pi pi-plus" label="Add&nbsp;clock" (click)="openNewClockDialog()"></p-button>
  }
  @if (window.innerWidth <= 740) {
    <p-button class="clock-add" icon="pi pi-plus" (click)="openNewClockDialog()"></p-button>
  }
</ng-template>

<tze-add-clock-dialog [(visible)]="showAddClockDialog" [(asLocal)]="selectLocal"
  [(timezone)]="selectedTimezone" (done)="createClock()"></tze-add-clock-dialog>
