<div class="banner">
  <h1 #title [class.title-focus]="true" [tabindex]="mobile ? 0 : -1">Timezone Database Explorer</h1>
  <h2 [style.visibility]="latestTzVersion ? 'visible' : 'hidden'"
        class="sub-header">Latest tz database version: {{latestTzVersion}}
    <i class="fas fa-info-circle fa-2x info-icon info-icon-adjust"
        [class.disabled]="!notes[latestTzVersion]"
        (click)="releaseNote = latestTzVersion"></i>
  </h2>
</div>
<p-tabs class="tab-view" [(value)]="tabIndex">
  <p-tablist>
    <p-tab [value]="CLOCKS">{{ 'Current Time/Time Conv' + (window.innerWidth < 620 ? '.' : 'ersions') }}</p-tab>
    <p-tab [value]="HISTORY">{{ (window.innerWidth < 620 ? 'TZ' : 'Timezone') + ' History' }}</p-tab>
    <p-tab [value]="DOWNLOADS">Downloads</p-tab>
    <p-tab [value]="CODE">Code</p-tab>
  </p-tablist>
  <p-tabpanels>
    <p-tabpanel [value]="CLOCKS">
      <tze-clocks></tze-clocks>
    </p-tabpanel>
    <p-tabpanel [value]="HISTORY">
      <tze-zone-history [hideTransitions]="tabIndex !== HISTORY"></tze-zone-history>
    </p-tabpanel>
    <p-tabpanel [value]="DOWNLOADS">
      <tze-downloads></tze-downloads>
    </p-tabpanel>
    <p-tabpanel [value]="CODE">
      <tze-code></tze-code>
    </p-tabpanel>
  </p-tabpanels>
</p-tabs>
<tze-release-note [(release)]="releaseNote"></tze-release-note>
