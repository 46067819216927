<div class="zone-search">
  <p-iconfield>
    <p-autoComplete #autoComplete class="wide" [(ngModel)]="searchText"
        placeholder="Search cities/timezone names"
        [suggestions]="matchZones"
        [forceSelection]="true"
        [showEmptyMessage]="true"
        [emptyMessage]="emptyMessage"
        (completeMethod)="searchSelect($event)"
        (onKeyUp)="checkForEnter($event)"
        [autofocus]="autofocus"
        appendTo="body"></p-autoComplete>
    <p-inputicon styleClass="pi pi-search"/>
  </p-iconfield>
  <i class="fas fa-spinner fa-pulse fa-fw" [style.display]="searching ? 'block' : 'none'"></i>
</div>

<div class="mode-selector">
  <div class="label-pair">
    <p-radioButton #byUtc [value]="true" [(ngModel)]="selectByOffset"></p-radioButton>
    <label [for]="byUtc">By UTC offset</label>
  </div>
  <div class="label-pair">
    <p-radioButton #byRegion [value]="false" [(ngModel)]="selectByOffset"></p-radioButton>
    <label [for]="byRegion">By region</label>
  </div>
</div>

<div class="zone-selector">
  <div class="by-region" [style.opacity]="+!selectByOffset" [style.pointer-events]="selectByOffset ? 'none' : 'auto'">
    <p-select
         [options]="regions" [(ngModel)]="region"  [disabled]="disabled || selectByOffset" (focus)="onSelectFocus($event)"
         appendTo="body" (blur)="onSelectBlur($event)"></p-select>
    <div class="caption">Timezone region/category</div>

    <p-select
         [options]="subzones" [(ngModel)]="subzone" [disabled]="disabled || selectByOffset" (focus)="onSelectFocus($event)"
         appendTo="body" (blur)="onSelectBlur($event)"></p-select>
    <div class="caption">Specific timezone</div>
  </div>

  <div class="by-offset" [style.opacity]="+selectByOffset" [style.pointer-events]="selectByOffset ? 'auto' : 'none'">
    <p-select
         [options]="offsets"  [(ngModel)]="offset"  [disabled]="disabled || !selectByOffset" (focus)="onSelectFocus($event)"
         appendTo="body" (blur)="onSelectBlur($event)"></p-select>
    <div class="caption">UTC offset/DST</div>

    <p-select
         [options]="zones" [(ngModel)]="zone" [disabled]="disabled || !selectByOffset" (focus)="onSelectFocus($event)"
         appendTo="body" (blur)="onSelectBlur($event)"></p-select>
    <div class="caption">Specific timezone</div>
  </div>
  <p [hidden]="!error" class="error">{{error}}</p>
</div>
