const t = Math.abs,
  n = Math.acos,
  e = Math.acosh;
function i(t) {
  return Math.acos(t) / Math.PI * 180;
}
function r(t) {
  return Math.PI / 2 - Math.atan(t);
}
function s(t, n) {
  return Math.PI / 2 - Math.atan2(t, n);
}
function a(t) {
  return 180 - Math.atan(t) / Math.PI * 180;
}
function h(t, n) {
  return 180 - Math.atan2(t, n) / Math.PI * 180;
}
const u = Math.asin,
  o = Math.asinh;
function c(t) {
  return Math.asin(t) / Math.PI * 180;
}
const N = Math.atan,
  I = Math.atan2,
  M = Math.atanh;
function l(t) {
  return Math.atan(t) / Math.PI * 180;
}
function _(t, n) {
  return Math.atan2(t, n) / Math.PI * 180;
}
const A = Math.cbrt;
function g(t, n = 1) {
  return 1 === n ? Math.ceil(t) : -(-t - X(-t, n));
}
const E = Math.clz32,
  S = Math.cos,
  R = Math.cosh;
function d(t) {
  return Math.cos(t / 180 * Math.PI);
}
function f(t, n) {
  const e = t / n;
  return e >= 0 ? Math.floor(e) : Math.ceil(e);
}
function G(t, n) {
  return Math.floor(t / n);
}
const x = Math.exp,
  T = Math.expm1;
function D(t, n = 1) {
  return 1 === n ? Math.floor(t) : t - X(t, n);
}
const O = Math.fround,
  w = Math.hypot,
  m = Math.imul;
function y(t, n, e, i, r) {
  return t === e ? i : i + (n - t) * (r - i) / (e - t);
}
function L(t, n, e, i, r, s, a = !1) {
  const h = s / 2;
  for ((i < 0 || i >= s) && (i = X(i, s)); r < i - h;) r += s;
  for (; r >= i + h;) r -= s;
  let u = y(t, n, e, i, r);
  return a && (u < h || u >= h) ? u = Y(u, s) : (u < 0 || u >= s) && (u = X(u, s)), u;
}
function C(t, n, e, i, r) {
  let s = 0;
  for (let a = i; a <= r; ++a) {
    let h = 1;
    for (let n = i; n <= r; ++n) n !== a && (h *= (e - t[n]) / (t[a] - t[n]));
    s += h * n[a];
  }
  return s;
}
function U(t, n, e, i, r) {
  const s = Math.min(t.length, n.length);
  let a = -1,
    h = s - 1;
  for (let n = 0; n < s; ++n) {
    const e = t[n];
    if (a < 0 && e >= r - i && (a = n), e >= r + i) {
      h = n;
      break;
    }
  }
  return C(t, n, e, Math.max(a, 0), h);
}
function p(t, n, e, i) {
  const r = Math.min(t.length, n.length);
  if (i <= 0) return C(t, n, e, 0, r - 1);
  let s = -1,
    a = -1;
  for (let i = 0; i < r; ++i) {
    const r = t[i];
    if (r === e) return n[i];
    if (r < e) s = i;else if (r > e) {
      a = i;
      break;
    }
  }
  if (s < 0) return n[0];
  if (a < 0) return n[r - 1];
  const h = t[s],
    u = t[a],
    o = (e - h) / (u - h);
  return U(t, n, e, i, h) * (1 - o) + U(t, n, e, i, u) * o;
}
function H(t, n) {
  return t.x < n.x + n.w && t.x + t.w > n.x && t.y < n.y + n.h && t.y + t.h > n.y;
}
function P(t, n) {
  return 1 === arguments.length ? 1 + Math.floor(Math.random() * t) : t + Math.floor(Math.random() * (n - t + 1));
}
function V(t, n = .01) {
  return t < -1 - n ? -1 : t > 1 + n ? 1 : t < -1 ? -1 : t > 1 ? 1 : t;
}
const b = Math.log,
  F = Math.log10,
  v = Math.log1p,
  k = Math.log2,
  z = Math.max,
  q = Math.min;
function X(t, n) {
  const e = t % n;
  return e < 0 && n > 0 || e > 0 && n < 0 ? n + e : e;
}
function Y(t, n) {
  let e = t - Math.floor(t / n) * n;
  return e >= n / 2 && (e -= n), e;
}
const Z = Math.pow;
function j(t, n) {
  switch (arguments.length) {
    case 1:
      return Math.random() * t;
    case 2:
      return t + Math.random() * (n - t);
  }
  return Math.random();
}
function B(t, n = 1) {
  return 1 === n ? Math.round(t) : t + n / 2 - X(t + n / 2, n);
}
const J = Math.sign;
function K(t) {
  return t > 0 ? 1 : -1;
}
function Q(t) {
  return t < 0 ? -1 : 1;
}
const W = Math.sin,
  $ = Math.sinh;
function tt(t) {
  return Math.sin(t / 180 * Math.PI);
}
const nt = Math.sqrt;
function et(t) {
  return t * t;
}
const it = Math.tan,
  rt = Math.tanh;
function st(t) {
  return Math.tan(t / 180 * Math.PI);
}
function at(t) {
  return 180 * t / Math.PI;
}
function ht(t) {
  return t * Math.PI / 180;
}
const ut = Math.trunc,
  ot = 2 * Math.PI;
function ct(t, n) {
  const e = q(t.x, n.x),
    i = q(t.y, n.y);
  return {
    x: e,
    y: i,
    w: z(t.x + t.w, n.x + n.w) - e,
    h: z(t.y + t.h, n.y + n.h) - i
  };
}
var Nt, It;
!function (t) {
  t[t.RADIANS = 0] = "RADIANS", t[t.DEGREES = 1] = "DEGREES", t[t.ARC_MINUTES = 2] = "ARC_MINUTES", t[t.ARC_SECONDS = 3] = "ARC_SECONDS", t[t.HOURS = 4] = "HOURS", t[t.HOUR_ANGLE_MINUTES = 5] = "HOUR_ANGLE_MINUTES", t[t.HOUR_ANGLE_SECONDS = 6] = "HOUR_ANGLE_SECONDS", t[t.ROTATIONS = 7] = "ROTATIONS", t[t.GRADS = 8] = "GRADS";
}(Nt || (Nt = {})), function (t) {
  t[t.RANGE_LIMIT_SIGNED = 0] = "RANGE_LIMIT_SIGNED", t[t.RANGE_LIMIT_NONNEGATIVE = 1] = "RANGE_LIMIT_NONNEGATIVE", t[t.RANGE_UNLIMITED = 2] = "RANGE_UNLIMITED";
}(It || (It = {}));
const Mt = Math.PI,
  lt = Mt / 2,
  _t = 2 * Mt,
  At = 1,
  gt = 1,
  Et = 2,
  St = 4,
  Rt = 8,
  dt = 16;
function ft(t, n) {
  switch (n) {
    case Nt.RADIANS:
      return t;
    case Nt.DEGREES:
      return t / 180 * Mt;
    case Nt.ARC_MINUTES:
      return t / 10800 * Mt;
    case Nt.ARC_SECONDS:
      return t / 648e3 * Mt;
    case Nt.HOURS:
      return t / 12 * Mt;
    case Nt.HOUR_ANGLE_MINUTES:
      return t / 720 * Mt;
    case Nt.HOUR_ANGLE_SECONDS:
      return t / 43200 * Mt;
    case Nt.ROTATIONS:
      return t * _t;
    case Nt.GRADS:
      return t / 200 * Mt;
  }
  return NaN;
}
function Gt(t, n) {
  switch (n) {
    case Nt.RADIANS:
      return t;
    case Nt.DEGREES:
      return 180 * t / Mt;
    case Nt.ARC_MINUTES:
      return 10800 * t / Mt;
    case Nt.ARC_SECONDS:
      return 648e3 * t / Mt;
    case Nt.HOURS:
      return 12 * t / Mt;
    case Nt.HOUR_ANGLE_MINUTES:
      return 720 * t / Mt;
    case Nt.HOUR_ANGLE_SECONDS:
      return 43200 * t / Mt;
    case Nt.ROTATIONS:
      return t / _t;
    case Nt.GRADS:
      return 200 * t / Mt;
  }
  return NaN;
}
class xt {
  constructor(t = 0, n, e = It.RANGE_LIMIT_SIGNED) {
    this.cached_sin = 2, this.cached_cos = 2, this.cached_tan = 0, void 0 === n && (n = Nt.RADIANS), e === It.RANGE_LIMIT_SIGNED ? this.angle = Y(ft(t, n), _t) : e === It.RANGE_LIMIT_NONNEGATIVE ? this.angle = X(ft(t, n), _t) : this.angle = ft(t, n);
  }
  static asin(t) {
    return new xt(Math.asin(t));
  }
  static asin_nonneg(t) {
    return new xt(Math.asin(t), Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  static acos(t) {
    return new xt(Math.acos(t));
  }
  static atan(t) {
    return new xt(Math.atan(t));
  }
  static atan_nonneg(t) {
    return new xt(Math.atan(t), Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  static atan2(t, n) {
    return new xt(Math.atan2(t, n));
  }
  static atan2_nonneg(t, n) {
    return new xt(Math.atan2(t, n), Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  get radians() {
    return this.angle;
  }
  get degrees() {
    return Gt(this.angle, Nt.DEGREES);
  }
  get arcMinutes() {
    return Gt(this.angle, Nt.ARC_MINUTES);
  }
  get arcSeconds() {
    return Gt(this.angle, Nt.ARC_SECONDS);
  }
  get hours() {
    return Gt(this.angle, Nt.HOURS);
  }
  get rotations() {
    return Gt(this.angle, Nt.ROTATIONS);
  }
  get grads() {
    return Gt(this.angle, Nt.GRADS);
  }
  getAngle(t = Nt.RADIANS) {
    return Gt(this.angle, t);
  }
  get sin() {
    return this.cached_sin > 1 && (this.cached_sin = Math.sin(this.angle)), this.cached_sin;
  }
  get cos() {
    return this.cached_cos > 1 && (this.cached_cos = Math.cos(this.angle)), this.cached_cos;
  }
  get tan() {
    return 0 === this.angle ? 0 : (0 === this.cached_tan && (this.cached_tan = Math.tan(this.angle)), this.cached_tan);
  }
  add(t, n = It.RANGE_LIMIT_SIGNED) {
    return new xt(this.angle + t.angle, Nt.RADIANS, n);
  }
  add_nonneg(t) {
    return new xt(this.angle + t.angle, Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  subtract(t, n = It.RANGE_LIMIT_SIGNED) {
    return new xt(this.angle - t.angle, Nt.RADIANS, n);
  }
  subtract_nonneg(t) {
    return new xt(this.angle - t.angle, Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  complement(t = It.RANGE_LIMIT_SIGNED) {
    return new xt(lt - this.angle, Nt.RADIANS, t);
  }
  complement_nonneg() {
    return new xt(lt - this.angle, Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  supplement(t = It.RANGE_LIMIT_SIGNED) {
    return new xt(Mt - this.angle, Nt.RADIANS, t);
  }
  supplement_nonneg() {
    return new xt(Mt - this.angle, Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  opposite(t = It.RANGE_LIMIT_SIGNED) {
    return new xt(this.angle + Mt, Nt.RADIANS, t);
  }
  opposite_nonneg() {
    return new xt(this.angle + Mt, Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  negate(t = It.RANGE_LIMIT_SIGNED) {
    return new xt(-this.angle, Nt.RADIANS, void 0 === t ? It.RANGE_UNLIMITED : t);
  }
  negate_nonneg() {
    return new xt(-this.angle, Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  multiply(t, n = It.RANGE_LIMIT_SIGNED) {
    return new xt(this.angle * t, Nt.RADIANS, n);
  }
  multiply_nonneg(t) {
    return new xt(this.angle * t, Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  divide(t, n = It.RANGE_LIMIT_SIGNED) {
    return new xt(this.angle / t, Nt.RADIANS, n);
  }
  divide_nonneg(t) {
    return new xt(this.angle / t, Nt.RADIANS, It.RANGE_LIMIT_NONNEGATIVE);
  }
  toString(t, n) {
    return xt.toStringAux(this.degrees, "°", "'", '"', t, n);
  }
  toSuffixedString(n, e, i, r) {
    return xt.toStringAux(t(this.degrees), "°", "'", '"', i, r) + (this.degrees < 0 ? e : n);
  }
  toHourString(t, n) {
    return xt.toStringAux(this.hours, "h", "m", "s", t, n);
  }
  toTimeString(t, n) {
    return xt.toStringAux(this.hours, ":", 4 === t ? "" : ":", "", t, n, 2);
  }
  static toStringAux(n, e, i, r, s, a, h = 0) {
    const u = 0 != (12 & (s = s || 0));
    0 != (1 & s) ? h = 2 : 0 != (2 & s) && (h = 3), null == a && (a = null != s && u ? 0 : 3);
    const o = Math.sign(n);
    let c;
    if (n = t(n), u) {
      const t = Z(10, a);
      if (0 != (4 & s)) {
        let r = B(60 * n * t) / t;
        n = D(r / 60), r %= 60, c = n + e + (r < 10 ? "0" : "") + r.toFixed(a) + i;
      } else {
        let s = B(3600 * n * t) / t,
          h = D(s / 60);
        s %= 60, n = D(h / 60), h %= 60, c = n + e + (h < 10 ? "0" : "") + h + i + (s < 10 ? "0" : "") + s.toFixed(a) + r;
      }
    } else c = n.toFixed(a) + e;
    if (h) {
      const t = h - /^(\d+)\D/.exec(c)[1].length;
      for (let n = 0; n < t; ++n) c = "0" + c;
    }
    return o < 0 ? c = "-" + c : 0 != (16 & s) && (c = "+" + c), c;
  }
}
xt.ZERO = new xt(0), xt.RIGHT = new xt(lt), xt.STRAIGHT = new xt(Mt);
class Tt {
  constructor(t, n, e, i, r, s) {
    this.minMaxSeekingFunction = t, this.tolerance = n, this.maxIterations = e, this.ax = i, this.bx = r, this.cx = s, this._iterationCount = 0, this.isMin = !0;
  }
  getXAtMinMax() {
    let n,
      e,
      i,
      r,
      s,
      a,
      h,
      u,
      o,
      c,
      N,
      I,
      M,
      l,
      _,
      A,
      g = 0,
      E = 0,
      S = 1;
    for (n = this.ax < this.cx ? this.ax : this.cx, e = this.ax > this.cx ? this.ax : this.cx, _ = l = M = this.bx, this.fx = this.minMaxSeekingFunction(_), this.fx > this.minMaxSeekingFunction(this.ax) ? (this.isMin = !1, this.fx *= -1, S = -1) : this.isMin = !0, a = s = this.fx, this._iterationCount = 0; ++this._iterationCount <= this.maxIterations;) {
      if (A = .5 * (n + e), c = this.tolerance * t(_) + 1e-20, N = 2 * c, t(_ - A) <= N - .5 * (e - n)) return this.fx *= S, _;
      t(E) > c ? (o = (_ - l) * (this.fx - s), u = (_ - M) * (this.fx - a), h = (_ - M) * u - (_ - l) * o, u = 2 * (u - o), u > 0 && (h = -h), u = t(u), i = E, E = g, t(h) >= t(.5 * u * i) || h <= u * (n - _) || h >= u * (e - _) ? (E = _ >= A ? n - _ : e - _, g = .381966011250105 * E) : (g = h / u, I = _ + g, (I - n < N || e - I < N) && (g = Math.sign(A - _) * c))) : (E = _ >= A ? n - _ : e - _, g = .381966011250105 * E), I = t(g) >= c ? _ + g : _ + Math.sign(g) * c, r = S * this.minMaxSeekingFunction(I), r <= this.fx ? (I >= _ ? n = _ : e = _, M = l, l = _, _ = I, s = a, a = this.fx, this.fx = r) : (I < _ ? n = I : e = I, r <= a || l === _ ? (M = l, l = I, s = a, a = r) : (r <= s || M === _ || M === l) && (M = I, s = r));
    }
    return this.fx *= S, _;
  }
  get foundMaximum() {
    return !this.isMin;
  }
  get foundMinimum() {
    return this.isMin;
  }
  get lastY() {
    return this.fx;
  }
  get iterationCount() {
    return this._iterationCount;
  }
}
class Dt {
  constructor(t = 0, n = 0, e = Nt.RADIANS, i = Nt.RADIANS) {
    this._longitude = "number" == typeof t ? new xt(t, e, It.RANGE_LIMIT_NONNEGATIVE) : t, this._latitude = "number" == typeof n ? new xt(n, i) : n;
  }
  get longitude() {
    return this._longitude;
  }
  get rightAscension() {
    return this._longitude;
  }
  get altitude() {
    return this._latitude;
  }
  get azimuth() {
    return this._longitude;
  }
  get latitude() {
    return this._latitude;
  }
  get declination() {
    return this._latitude;
  }
  distanceFrom(e) {
    let i = n(V(this._latitude.sin * e._latitude.sin + this._latitude.cos * e._latitude.cos * this._longitude.subtract(e._longitude).cos));
    return i = t(Y(i, ot)), new xt(i);
  }
  toString() {
    return "lon: " + this.longitude + ", lat: " + this.latitude;
  }
}
class Ot extends Dt {
  constructor(t, n, e = 0, i, r) {
    super(t, n, i, r), this._radius = e;
  }
  static convertRectangular(t, n, e) {
    let i;
    if ("number" == typeof t) {
      if (i = t, void 0 === n || void 0 === e) throw new Error("Invalid arguments");
    } else i = t.x, n = t.y, e = t.z;
    return new Ot(xt.atan2_nonneg(n, i), xt.atan2(e, Math.sqrt(i * i + n * n)), Math.sqrt(i * i + n * n + e * e));
  }
  static from2D(t, n) {
    return new Ot(t.longitude, t.latitude, n);
  }
  get radius() {
    return this._radius;
  }
  get xyz() {
    return {
      x: this._radius * this._latitude.cos * this._longitude.cos,
      y: this._radius * this._latitude.cos * this._longitude.sin,
      z: this._radius * this._latitude.sin
    };
  }
  translate(t) {
    const n = t.longitude,
      e = t.latitude,
      i = t.radius,
      r = this.longitude,
      s = this.latitude,
      a = this.radius,
      h = a * s.cos * r.cos - i * e.cos * n.cos,
      u = a * s.cos * r.sin - i * e.cos * n.sin,
      o = a * s.sin - i * e.sin;
    return Ot.convertRectangular(h, u, o);
  }
  toString() {
    return super.toString() + ", rad: " + this.radius.toFixed(5);
  }
}
class wt {
  constructor(t, n, e, i, r, s, a) {
    this.zeroSeekingFunction = t, this.tolerance = n, this.maxIterations = e, this.x1 = i, this.y1 = r, this._iterationCount = 0, null == s ? (this.x2 = r, this.y1 = t(i), this.y2 = t(r)) : (this.x2 = s, this.y2 = a);
  }
  getXAtZero() {
    let t;
    for (this._iterationCount = 0; ++this._iterationCount <= this.maxIterations && (t = this.x1 - this.y1 / (this.y2 - this.y1) * (this.x2 - this.x1), this.y = this.zeroSeekingFunction(t), !(Math.abs(this.y) <= this.tolerance));) this.y1 < this.y2 && this.y < 0 || this.y1 > this.y2 && this.y > 0 ? (this.x1 = t, this.y1 = this.y) : (this.x2 = t, this.y2 = this.y);
    return t;
  }
  get lastY() {
    return this.y;
  }
  get iterationCount() {
    return this._iterationCount;
  }
}
export { xt as Angle, At as FMT_DD, Et as FMT_DDD, gt as FMT_HH, St as FMT_MINS, Rt as FMT_SECS, dt as FMT_SIGNED, lt as HALF_PI, Tt as MinMaxFinder, It as Mode, Mt as PI, Dt as SphericalPosition, Ot as SphericalPosition3D, _t as TWO_PI, Nt as Unit, wt as ZeroFinder, t as abs, n as acos, i as acos_deg, e as acosh, r as acot, s as acot2, h as acot2_deg, a as acot_deg, u as asin, c as asin_deg, o as asinh, N as atan, I as atan2, _ as atan2_deg, l as atan_deg, M as atanh, A as cbrt, g as ceil, E as clz32, Gt as convertFromRadians, ft as convertToRadians, S as cos, d as cos_deg, R as cosh, G as div_rd, f as div_tt0, x as exp, T as expm1, D as floor, O as fround, w as hypot, m as imul, y as interpolate, L as interpolateModular, p as interpolateTabular, H as intersects, P as irandom, V as limitNeg1to1, b as log, F as log10, v as log1p, k as log2, z as max, q as min, X as mod, Y as mod2, Z as pow, j as random, B as round, J as sign, K as signZN, Q as signZP, W as sin, tt as sin_deg, $ as sinh, nt as sqrt, et as squared, it as tan, st as tan_deg, rt as tanh, at as to_degree, ht as to_radian, ut as trunc, ct as union };
