<p-dialog header="Add clock" [(visible)]="visible" [modal]="true" position="top">
  <div class="clock-dialog">
    <tze-zone-selector [(ngModel)]="timezone" [autofocus]="true"></tze-zone-selector>
    <div class="clock-format">
      <div class="label-pair">
        <p-radioButton #isoFormat name="clockFormat" [value]="false" [(ngModel)]="asLocal"></p-radioButton>
        <label [for]="isoFormat">ISO date/time format</label>
      </div>
      <div class="label-pair">
        <p-radioButton #localFormat name="clockFormat" [value]="true" [(ngModel)]="asLocal"></p-radioButton>
        <label [for]="localFormat">Your local date/time format</label>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button type="button" pButton (click)="visible = false">Cancel</button>
    <button type="button" pButton (click)="submit()">OK</button>
  </ng-template>
</p-dialog>
